import { React, useEffect, useState } from "react";
import { APIURL } from "../Methods/Fetch";
import { Navigate, useNavigate } from "react-router-dom";
import { axiosPost } from "../Methods/Save";
import Header from "../Components/Header";
import Sidebar from "../Components/MyAccount/Sidebar";
import MyOrders from "../Components/MyAccount/MyOrders";
import OrderDetails from "../Components/MyAccount/OrderDetails";
import Tickets from "../Components/MyAccount/Tickets";
import CreateTicket from "../Components/MyAccount/CreateTicket";
import ViewTicket from "../Components/MyAccount/ViewTicket";
import ContactDetails from "../Components/MyAccount/ContactDetails";
import Tasks from "../Components/MyAccount/Tasks";
import CreateTask from "../Components/MyAccount/CreateTask";
import ViewTask from "../Components/MyAccount/ViewTask";
import MyInvoices from "../Components/MyAccount/MyInvoices";
import PayMyBill from "../Components/MyAccount/PayMyBill";
import OrderList from "../Components/MyAccount/OrderList";
import UpdateDirectDebitDetails from "../Components/MyAccount/UpdateDirectDebitDetails";
import MyReferral from "../Components/MyAccount/MyReferral";
function Task() {
    const navigate = useNavigate();
    const [tab, setTab] = useState("");
    const [orderNo, setOrderNo] = useState();
    const [ddDetails, setDdDetails] = useState();
    const [monthly_total, setMonthlyTotal] = useState();
    const [vertical, setvertical] = useState("top");
    const [horizontal, sethori] = useState("right");
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [alerttype, setAlertType] = useState("");
    const [userDetails, setUserDetails] = useState(
      JSON.parse(localStorage.getItem("user_details"))
    );
    useEffect(() => {
        if (localStorage.getItem("logged_in") === null) navigate("/login");
    }, []);


    const [formData, setFormData] = useState({
        name: '',
        email: ''
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        var url = APIURL() + `create-task`;
        // Make API call here
        axiosPost(url, formData).then((response) => {
            // Handle success
            console.log(response.data);
        })
            .catch(error => {
                // Handle error
                console.error(error);
            });
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    return (
        <>
        <Header shoBblueLogo={true} myaccount={true} />
        <div className="body-box-margin">
        <section className="profile-sec">
          <div className="container-fluid">
            <div className="row">
            <Sidebar userDetails={userDetails} tab={tab} setTab={setTab} />

            
            {tab === "order-details" ? (
                <OrderDetails orderNo={orderNo} setTab={setTab} />
              ) : tab === "my-order" ? (
                <MyOrders setTab={setTab} setOrderNo={setOrderNo} />
              ) : tab === "tickets" ? (
                <Tickets setTab={setTab} />
              ) : tab === "create-ticket" ? (
                <CreateTicket
                  setMessage={setMessage}
                  setOpen={setOpen}
                  setAlertType={setAlertType}
                  setTab={setTab}
                />
              ) : tab === "view-ticket" ? (
                <ViewTicket userDetails={userDetails} setTab={setTab} />
              ) : tab === "contact-details" ? (
                <ContactDetails
                  userDetails={userDetails}
                  setMessage={setMessage}
                  setOpen={setOpen}
                  setAlertType={setAlertType}
                  setTab={setTab}
                />
              ) : tab === "tasks" ? (
                <Tasks setTab={setTab} />
              ) : tab === "create-task" ? (
                <CreateTask
                  userDetails={userDetails}
                  setMessage={setMessage}
                  setOpen={setOpen}
                  setAlertType={setAlertType}
                  setTab={setTab}
                />
              ) : tab === "view-task" ? (
                <ViewTask
                  userDetails={userDetails}
                  setMessage={setMessage}
                  setOpen={setOpen}
                  setAlertType={setAlertType}
                  setTab={setTab}
                />
              ) : tab === "invoices" ? (
                <MyInvoices
                  userDetails={userDetails}
                  setMessage={setMessage}
                  setOpen={setOpen}
                  setAlertType={setAlertType}
                  setTab={setTab}
                />
              ) : tab === "paymybill" ? (
                <PayMyBill
                  userDetails={userDetails}
                  setMessage={setMessage}
                  setOpen={setOpen}
                  setAlertType={setAlertType}
                  setTab={setTab}
                  orderNo={orderNo}
                  ddDetails={ddDetails}
                  monthly_total={monthly_total}
                />
              ) : tab === "order-list" ? (
                <OrderList
                  userDetails={userDetails}
                  setMessage={setMessage}
                  setOpen={setOpen}
                  setAlertType={setAlertType}
                  setTab={setTab}
                  setOrderNo={setOrderNo}
                  setDdDetails={setDdDetails}
                  setMonthlyTotal={setMonthlyTotal}
                />
              ) : tab === "update-dd" ? (
                <UpdateDirectDebitDetails
                  userDetails={userDetails}
                  setMainMessage={setMessage}
                  setOpen={setOpen}
                  setAlertType={setAlertType}
                  setTab={setTab}
                  orderNo={orderNo}
                  ddDetails={ddDetails}
                  monthly_total={monthly_total}
                />
              ) : tab === "referrals" ? (
                <MyReferral />
              ) : (
                ""
              )}
            <form onSubmit={handleSubmit}>
                <input type="text" name="summary" onChange={handleChange} placeholder="Summary" /><br></br>
                <input type="text" name="detail" onChange={handleChange} placeholder="Detail" /><br></br>
                <input type="text" name="priority_id" onChange={handleChange} placeholder="PriorityId" /><br></br>
                <input type="text" name="due_date" onChange={handleChange} placeholder="dueDate" /><br></br>
                <input type="text" name="owner" onChange={handleChange} placeholder="owner" /><br></br>
                <input type="text" name="category" onChange={handleChange} placeholder="category" /><br></br>
                <input type="text" name="subcategory" onChange={handleChange} placeholder="subcategory" /><br></br>
                <button type="submit">Submit</button>
            </form>
            </div>
            </div>
            </section>
            </div>
        </>
    );

}
export default Task;
