import React from "react";
import profile from "../../images/profile.png";
import { Link, useNavigate } from "react-router-dom";
import { Logout } from "@mui/icons-material";

function Sidebar(props) {
  const navigate = useNavigate();
  const logout = () => {
    localStorage.removeItem("user_id");
    localStorage.removeItem("user_details");
    localStorage.removeItem("logged_in");
    navigate("/");
  };
  const { setTab, userDetails, tab } = props;
  return (
    <>
      <div className="col-lg-3 ">
        <div className="profile-box">
          <div className="profile-icon-content mb-4">
            <img src={profile} alt="banner_img" />
            <div className="profile-text">
              <span>Hello,</span>
              <h3>
                {userDetails
                  ? `${userDetails.first_name} ${userDetails.last_name}`
                  : ""}
              </h3>
            </div>
          </div>
          <div className="profile-category">
            <div className={`profile-category-box ${tab === "my-order" ? "active" : ""}`}>
              <div className="icon-cat">
                <i className="fas fa-bags-shopping"></i>
              </div>
              <Link
                onClick={(e) => setTab("my-order")}
                style={{ cursor: "pointer" }}
              >
                My Order{" "}
              </Link>
            </div>

            <div className={`profile-category-box ${tab === "paymybill" ? "active" : ""}`}>
              <div className="icon-cat">
                <i className="fal fa-wallet"></i>
              </div>
              <Link
                onClick={(e) => setTab("paymybill")}
                style={{ cursor: "pointer" }}
              >
                Pay my bill
              </Link>
            </div>
            <div className={`profile-category-box ${tab === "invoices" ? "active" : ""}`}>
              <div className="icon-cat">
                <i className="fal fa-wallet"></i>
              </div>
              <Link
                onClick={(e) => setTab("invoices")}
                style={{ cursor: "pointer" }}
              >
                View Invoices{" "}
              </Link>
            </div>
            {/*<div className="profile-category-box">
              <div className="icon-cat">
                <i className="fad fa-sync-alt"></i>
              </div>
              <Link onClick={(e) => setTab("referrals")}
              style={{ cursor: "pointer" }}>Referrals </Link>
                </div>*/}
            <div className={`profile-category-box ${tab === "create-akj-task" ? "active" : ""}`}>
              <div className="icon-cat">
                <i className="fal fa-question-square"></i>
              </div>
              <Link
                //onClick={(e) => setTab("tasks")}
                onClick={(e) => setTab("create-akj-task")}
                style={{ cursor: "pointer" }}
              >
                Ask a question - Tasks{" "}
              </Link>
            </div>
            <div className={`profile-category-box ${tab === "order-list" ? "active" : ""}`}>
              <div className="icon-cat">
                <i className="fal fa-question-square"></i>
              </div>
              <Link
                onClick={(e) => setTab("order-list")}
                style={{ cursor: "pointer" }}
              >
                Direct debit details{" "}
              </Link>
            </div>
            <div className={`profile-category-box ${tab === "contact-details" ? "active" : ""}`}>
              <div className="icon-cat">
                <i className="fas fa-user"></i>
              </div>
              <Link
                onClick={(e) => setTab("contact-details")}
                style={{ cursor: "pointer" }}
              >
                Contact details{" "}
              </Link>
            </div>
            {/* <div className="profile-category-box">
              <div className="icon-cat">
                <i className="fal fa-ballot-check"></i>
              </div>
              <Link
                //nClick={(e) => setTab("tickets")}
                onClick={(e) => setTab("create-akj-ticket")}
                style={{ cursor: "pointer" }}
              >
                Tickets
              </Link>
            </div> */}
            <div className="profile-category-box">
              <div className="icon-cat">
                <i className="fal fa-sign-out-alt"></i>
              </div>
              <a onClick={(e) => logout()} style={{ cursor: "pointer" }}>
                Logout
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
