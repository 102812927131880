import "./App.css";
import { BrowserRouter, Routes, Route, useParams } from "react-router-dom";
import Homepage from "./Views/Homepage";
import { lazy, Suspense, useEffect, useState } from "react";
import { Backdrop, Box, CircularProgress } from "@mui/material";
import Bubbles from "./Components/Bubbles";
import Marketting from "./Views/InnerPages/BroadbandPayment/Marketting";
import OfferPage from "./Views/InnerPages/BroadbandPayment/OfferPage";
import MakePayment from "./Views/MakePayment";
import ThankYou from "./Views/ThankYou";
import Paypal from "./Components/MakePayment/Paypal";
import NoProductAvailable from "./Views/InnerPages/BroadbandPayment/NoProductAvailable";
import CookiesModal from "./Views/CookiesModal";
import Task from "./Views/Task";
import { useCookies } from "react-cookie";
import FullFibrePage from "./Views/FullFibre";
import CookiePolicy from "./Views/CookiePolicy";

const ZipcodeAddress = lazy(() =>
  import("./Views/InnerPages/BroadbandPayment/ZipcodeAddress")
);
const ProductPriceSpeed = lazy(() =>
  import("./Views/InnerPages/BroadbandPayment/ProductPriceSpeed")
);
const UnlimitedPlans = lazy(() =>
  import("./Views/InnerPages/BroadbandPayment/UnlimitedPlans")
);
const ContractCheckout = lazy(() =>
  import("./Views/InnerPages/BroadbandPayment/ContractCheckout")
);
const ContractInstallation = lazy(() =>
  import("./Views/InnerPages/BroadbandPayment/ContractInstallation")
);
const CompletionPage = lazy(() =>
  import("./Views/InnerPages/BroadbandPayment/CompletionPage")
);
const PaymentPage = lazy(() =>
  import("./Views/InnerPages/BroadbandPayment/PaymentPage")
);
const FinalPage = lazy(() =>
  import("./Views/InnerPages/BroadbandPayment/FinalPage")
);
const Tv = lazy(() => import("./Views/Tv"));
const NotAvailable = lazy(() =>
  import("./Views/InnerPages/BroadbandPayment/NotAvailable")
);
const LandLine = lazy(() => import("./Views/LandLine"));
const Mobile = lazy(() => import("./Views/Mobile"));
const Topdeals = lazy(() => import("./Views/Topdeals"));
const Broadband = lazy(() => import("./Views/Broadband"));
const Bussiness = lazy(() => import("./Views/Bussiness"));
const Shop = lazy(() => import("./Views/Shop"));
const BussinessBroadband = lazy(() =>
  import("./Views/BussinessPages/BussinessBroadband")
);
const Login = lazy(() => import("./Views/Login"));
const Blog = lazy(() => import("./Views/Blog"));
const HelpAndSupport = lazy(() => import("./Views/HelpAndSupport"));
const Myaccount = lazy(() => import("./Views/Myaccount"));
const Article = lazy(() => import("./Views/Article"));
const Notfound = lazy(() => import("./Views/Notfound"));
const Contact = lazy(() => import("./Views/Contact"));
const Privacy = lazy(() => import("./Views/Privacy"));
const TermsConditions = lazy(() => import("./Views/TermsConditions"));
const Legal = lazy(() => import("./Views/Legal"));
const Faq = lazy(() => import("./Views/Faq"));
const Landingpage = lazy(() => import("./Views/Landingpage"));
const AgentOrder = lazy(() => import("./Views/Agent/AgentOrder"));
const BussinessShop = lazy(() =>
  import("./Views/BussinessPages/BussinessShop")
);
const BussinessTopDeals = lazy(() =>
  import("./Views/BussinessPages/BussinessTopDeals")
);
const BussinessMobile = lazy(() =>
  import("./Views/BussinessPages/BussinessMobile")
);
const BussinessLandline = lazy(() =>
  import("./Views/BussinessPages/BussinessLandline")
);
const ScrollToTop = lazy(() => import("./Methods/ScrollTopTop"));
const Compare = lazy(() =>
  import("./Views/InnerPages/BroadbandPayment/MobilePayment/Compare")
);
const GoLive = lazy(() => import("./Views/InnerPages/BroadbandPayment/GoLive"));

function App() {
  const [metaDesc, setMetaDesc] = useState("");
  const [metaKeyWords, setMetaKw] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  //const [pageTitle, setPageTitle] = useState("");
  const [ogTitle, setOgtitle] = useState("");
  const [ogDesc, setOgDesc] = useState("");
  const [ogSiteName, setOgSiteName] = useState("");
  const [twitterCard, setTwitterCard] = useState("");
  const [twitterSite, setTwitterSite] = useState("");
  const [twitterTitle, setTwitterTitle] = useState("");
  const [twitterDesc, setTwitterDesc] = useState("");
  function setOpenGraphMetadata(property, content) {
    var metaTag = document.createElement("meta");
    metaTag.setAttribute("property", property);
    metaTag.setAttribute("content", content);
    document.head.appendChild(metaTag);
  }
  useEffect(() => {
    // document.pageTitle = `${pageTitle} | Pop Telecom`;
    document
      .querySelector('meta[name="title"]')
      .setAttribute("content", metaTitle ? metaTitle : "");
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", metaDesc ? metaDesc : "");
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute("content", metaKeyWords ? metaKeyWords : "");
    if (document.querySelector('meta[name="twitter:card"]')) {
      document
        .querySelector('meta[name="twitter:card"]')
        .setAttribute("content", twitterCard ? twitterCard : "");
    } else {
      var meta = document.createElement("meta");
      meta.name = "twitter:card";
      meta.content = twitterCard;
      document.getElementsByTagName("head")[0].append(meta);
    }
    if (document.querySelector('meta[name="twitter:title"]')) {
      document
        .querySelector('meta[name="twitter:title"]')
        .setAttribute("content", twitterTitle ? twitterTitle : "");
    } else {
      var meta = document.createElement("meta");
      meta.name = "twitter:title";
      meta.content = twitterTitle;
      document.getElementsByTagName("head")[0].append(meta);
    }
    if (document.querySelector('meta[name="twitter:description"]')) {
      document
        .querySelector('meta[name="twitter:description"]')
        .setAttribute("content", twitterDesc ? twitterDesc : "");
    } else {
      var meta = document.createElement("meta");
      meta.name = "twitter:description";
      meta.content = twitterDesc;
      document.getElementsByTagName("head")[0].append(meta);
    }
    if (document.querySelector('meta[name="twitter:site"]')) {
      document
        .querySelector('meta[name="twitter:site"]')
        .setAttribute("content", twitterSite ? twitterSite : "");
    } else {
      var meta = document.createElement("meta");
      meta.name = "twitter:site";
      meta.content = twitterSite;
      document.getElementsByTagName("head")[0].append(meta);
    }
    if (document.querySelector('meta[property="og:title"]')) {
      document
        .querySelector('meta[property="og:title"]')
        .setAttribute("content", ogTitle ? ogTitle : "");
    } else {
      setOpenGraphMetadata("og:title", ogTitle);
    }
    if (document.querySelector('meta[property="og:description"]')) {
      document
        .querySelector('meta[property="og:description"]')
        .setAttribute("content", ogDesc ? ogDesc : "");
    } else {
      setOpenGraphMetadata("og:description", ogDesc);
    }
    if (document.querySelector('meta[property="og:site_name"]')) {
      document
        .querySelector('meta[property="og:site_name"]')
        .setAttribute("content", ogSiteName ? ogSiteName : "");
    } else {
      setOpenGraphMetadata("og:site_name", ogSiteName);
    }
  }, [metaDesc, metaKeyWords, metaTitle]);

  const [cookies, setCookie] = useCookies(["name"]);

  return (
    <Suspense
      fallback={
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
          onBackdropClick="false"
        >
          <Box
            sx={{ position: "relative", display: "block", textAlign: "center" }}
          >
            <CircularProgress color="inherit" />
          </Box>
        </Backdrop>
      }
    >
      <CookiesModal />
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route
            path="/"
            element={
              <Homepage
                setMetaDesc={setMetaDesc}
                setMetaKw={setMetaKw}
                setOgtitle={setOgtitle}
                setOgDesc={setOgDesc}
                setOgSiteName={setOgSiteName}
                setTwitterCard={setTwitterCard}
                setTwitterTitle={setTwitterTitle}
                setTwitterSite={setTwitterSite}
                setTwitterDesc={setTwitterDesc}
                setMetaTitle={setMetaTitle}
              />
            }
          ></Route>
          <Route
            path="/full-fibre"
            element={
              <FullFibrePage
                setMetaDesc={setMetaDesc}
                setMetaKw={setMetaKw}
                setOgtitle={setOgtitle}
                setOgDesc={setOgDesc}
                setOgSiteName={setOgSiteName}
                setTwitterCard={setTwitterCard}
                setTwitterTitle={setTwitterTitle}
                setTwitterSite={setTwitterSite}
                setTwitterDesc={setTwitterDesc}
                setMetaTitle={setMetaTitle}
              />
            }
          ></Route>
          <Route
            path="/agent-order/:agentid/:orderId"
            element={<AgentOrder />}
          ></Route>
          <Route path="/agent-order/:agentid" element={<AgentOrder />}></Route>
          <Route
            path="/:type/:productname/:affName"
            element={<Landingpage />}
          ></Route>
          <Route path="/:type/:productname" element={<Landingpage />}></Route>
          <Route path="/paypal" element={<Paypal />}></Route>
          <Route path="/address" element={<ZipcodeAddress />}></Route>
          <Route
            path="/faq"
            element={
              <Faq
                setMetaDesc={setMetaDesc}
                setMetaKw={setMetaKw}
                setOgtitle={setOgtitle}
                setOgDesc={setOgDesc}
                setOgSiteName={setOgSiteName}
                setTwitterCard={setTwitterCard}
                setTwitterTitle={setTwitterTitle}
                setTwitterSite={setTwitterSite}
                setTwitterDesc={setTwitterDesc}
                setMetaTitle={setMetaTitle}
              />
            }
          ></Route>
          <Route
            path="/productpricespeed"
            element={<ProductPriceSpeed />}
          ></Route>
          <Route path="/unlimitedplans" element={<UnlimitedPlans />}></Route>
          <Route
            path="/contractcheckout"
            element={<ContractCheckout />}
          ></Route>
          <Route
            path="/contractinstallation"
            element={<ContractInstallation />}
          ></Route>
          <Route
            path="/continue-order/:orderId"
            element={<ContractCheckout />}
          ></Route>
          <Route
            path="/business-make-payment"
            element={<MakePayment business={true} />}
          ></Route>
          <Route path="/make-payment" element={<MakePayment />}></Route>
          <Route path="/thank-you" element={<ThankYou />}></Route>
          <Route
            path="/thank-you/paypal"
            element={<ThankYou paymentMode="paypal" />}
          ></Route>
          <Route
            path="/business-offer-page"
            element={<OfferPage business={true} />}
          ></Route>
          <Route path="/offer-page" element={<OfferPage />}></Route>
          <Route path="/marketing" element={<Marketting />}></Route>
          <Route path="/golive" element={<GoLive />}></Route>
          <Route path="/payment" element={<PaymentPage />}></Route>
          <Route path="/completionpage" element={<CompletionPage />}></Route>
          <Route path="/paymentconfirm" element={<FinalPage />}></Route>
          <Route
            path="/tv"
            element={
              <Tv
                setMetaDesc={setMetaDesc}
                setMetaKw={setMetaKw}
                setOgtitle={setOgtitle}
                setOgDesc={setOgDesc}
                setOgSiteName={setOgSiteName}
                setTwitterCard={setTwitterCard}
                setTwitterTitle={setTwitterTitle}
                setTwitterSite={setTwitterSite}
                setTwitterDesc={setTwitterDesc}
                setMetaTitle={setMetaTitle}
              />
            }
          ></Route>
          <Route
            path="/landline"
            element={
              <LandLine
                setMetaDesc={setMetaDesc}
                setMetaKw={setMetaKw}
                setOgtitle={setOgtitle}
                setOgDesc={setOgDesc}
                setOgSiteName={setOgSiteName}
                setTwitterCard={setTwitterCard}
                setTwitterTitle={setTwitterTitle}
                setTwitterSite={setTwitterSite}
                setTwitterDesc={setTwitterDesc}
                setMetaTitle={setMetaTitle}
              />
            }
          ></Route>
          <Route
            path="/mobile"
            element={
              <Mobile
                setMetaDesc={setMetaDesc}
                setMetaKw={setMetaKw}
                setOgtitle={setOgtitle}
                setOgDesc={setOgDesc}
                setOgSiteName={setOgSiteName}
                setTwitterCard={setTwitterCard}
                setTwitterTitle={setTwitterTitle}
                setTwitterSite={setTwitterSite}
                setTwitterDesc={setTwitterDesc}
                setMetaTitle={setMetaTitle}
              />
            }
          ></Route>
          <Route
            path="/top-deals"
            element={
              <Topdeals
                setMetaDesc={setMetaDesc}
                setMetaKw={setMetaKw}
                setMetaTitle={setMetaTitle}
                setOgtitle={setOgtitle}
                setOgDesc={setOgDesc}
                setOgSiteName={setOgSiteName}
                setTwitterCard={setTwitterCard}
                setTwitterTitle={setTwitterTitle}
                setTwitterSite={setTwitterSite}
                setTwitterDesc={setTwitterDesc}
              />
            }
          ></Route>
          <Route
            path="/shop"
            element={
              <Shop
                setMetaDesc={setMetaDesc}
                setMetaKw={setMetaKw}
                setOgtitle={setOgtitle}
                setOgDesc={setOgDesc}
                setOgSiteName={setOgSiteName}
                setTwitterCard={setTwitterCard}
                setTwitterTitle={setTwitterTitle}
                setTwitterSite={setTwitterSite}
                setTwitterDesc={setTwitterDesc}
                setMetaTitle={setMetaTitle}
              />
            }
          ></Route>
          <Route
            path="/broadband"
            element={
              <Broadband
                setMetaDesc={setMetaDesc}
                setMetaKw={setMetaKw}
                setOgtitle={setOgtitle}
                setOgDesc={setOgDesc}
                setOgSiteName={setOgSiteName}
                setTwitterCard={setTwitterCard}
                setTwitterTitle={setTwitterTitle}
                setTwitterSite={setTwitterSite}
                setTwitterDesc={setTwitterDesc}
                setMetaTitle={setMetaTitle}
              />
            }
          ></Route>
          <Route path="/not-available" element={<NotAvailable />}></Route>
          <Route
            path="/no-products-available"
            element={<NoProductAvailable />}
          ></Route>
          <Route
            path="/login"
            element={
              <Login
                setMetaDesc={setMetaDesc}
                setMetaKw={setMetaKw}
                setOgtitle={setOgtitle}
                setOgDesc={setOgDesc}
                setOgSiteName={setOgSiteName}
                setTwitterCard={setTwitterCard}
                setTwitterTitle={setTwitterTitle}
                setTwitterSite={setTwitterSite}
                setTwitterDesc={setTwitterDesc}
                setMetaTitle={setMetaTitle}
              />
            }
          ></Route>
          <Route path="/my-account" element={<Myaccount />}></Route>
          <Route path="/task" element={<Task />}></Route>
          <Route
            path="/business-compare-products"
            element={<Compare business={true} />}
          ></Route>
          <Route path="/compare-products" element={<Compare />}></Route>
          <Route
            path="/help-support"
            element={
              <HelpAndSupport
                setMetaDesc={setMetaDesc}
                setMetaKw={setMetaKw}
                setOgtitle={setOgtitle}
                setOgDesc={setOgDesc}
                setOgSiteName={setOgSiteName}
                setTwitterCard={setTwitterCard}
                setTwitterTitle={setTwitterTitle}
                setTwitterSite={setTwitterSite}
                setTwitterDesc={setTwitterDesc}
                setMetaTitle={setMetaTitle}
              />
            }
          ></Route>
          <Route path="/help-support/:search" element={<Blog />}></Route>
          <Route
            path="/help-support/:category/:title"
            element={
              <Article
                setMetaDesc={setMetaDesc}
                setMetaKw={setMetaKw}
                setOgtitle={setOgtitle}
                setOgDesc={setOgDesc}
                setOgSiteName={setOgSiteName}
                setTwitterCard={setTwitterCard}
                setTwitterTitle={setTwitterTitle}
                setTwitterSite={setTwitterSite}
                setTwitterDesc={setTwitterDesc}
                setMetaTitle={setMetaTitle}
              />
            }
          ></Route>
          <Route
            path="/contact-us"
            element={
              <Contact
                setMetaDesc={setMetaDesc}
                setMetaKw={setMetaKw}
                setOgtitle={setOgtitle}
                setOgDesc={setOgDesc}
                setOgSiteName={setOgSiteName}
                setTwitterCard={setTwitterCard}
                setTwitterTitle={setTwitterTitle}
                setTwitterSite={setTwitterSite}
                setTwitterDesc={setTwitterDesc}
                setMetaTitle={setMetaTitle}
              />
            }
          ></Route>

          <Route
            path="/business-contact-us"
            element={<Contact bussiness={true} />}
          ></Route>
          <Route
            path="/privacy"
            element={
              <Privacy
                setMetaDesc={setMetaDesc}
                setMetaKw={setMetaKw}
                setOgtitle={setOgtitle}
                setOgDesc={setOgDesc}
                setOgSiteName={setOgSiteName}
                setTwitterCard={setTwitterCard}
                setTwitterTitle={setTwitterTitle}
                setTwitterSite={setTwitterSite}
                setTwitterDesc={setTwitterDesc}
                setMetaTitle={setMetaTitle}
              />
            }
          />
          <Route
            path="/terms-conditions"
            element={
              <TermsConditions
                setMetaDesc={setMetaDesc}
                setMetaKw={setMetaKw}
                setOgtitle={setOgtitle}
                setOgDesc={setOgDesc}
                setOgSiteName={setOgSiteName}
                setTwitterCard={setTwitterCard}
                setTwitterTitle={setTwitterTitle}
                setTwitterSite={setTwitterSite}
                setTwitterDesc={setTwitterDesc}
                setMetaTitle={setMetaTitle}
              />
            }
          />
          <Route
            path="/legal"
            element={
              <Legal
                setMetaDesc={setMetaDesc}
                setMetaKw={setMetaKw}
                setOgtitle={setOgtitle}
                setOgDesc={setOgDesc}
                setOgSiteName={setOgSiteName}
                setTwitterCard={setTwitterCard}
                setTwitterTitle={setTwitterTitle}
                setTwitterSite={setTwitterSite}
                setTwitterDesc={setTwitterDesc}
                setMetaTitle={setMetaTitle}
              />
            }
          />
          <Route path="/bubbles" element={<Bubbles />} />

          <Route
            path="/business"
            element={
              <Bussiness
                setMetaDesc={setMetaDesc}
                setMetaKw={setMetaKw}
                setOgtitle={setOgtitle}
                setOgDesc={setOgDesc}
                setOgSiteName={setOgSiteName}
                setTwitterCard={setTwitterCard}
                setTwitterTitle={setTwitterTitle}
                setTwitterSite={setTwitterSite}
                setTwitterDesc={setTwitterDesc}
                setMetaTitle={setMetaTitle}
              />
            }
          ></Route>
          <Route
            path="/business-broadband"
            element={
              <BussinessBroadband
                setMetaDesc={setMetaDesc}
                setMetaKw={setMetaKw}
                setOgtitle={setOgtitle}
                setOgDesc={setOgDesc}
                setOgSiteName={setOgSiteName}
                setTwitterCard={setTwitterCard}
                setTwitterTitle={setTwitterTitle}
                setTwitterSite={setTwitterSite}
                setTwitterDesc={setTwitterDesc}
                setMetaTitle={setMetaTitle}
              />
            }
          ></Route>
          <Route
            path="/business-landline"
            element={
              <BussinessLandline
                setMetaDesc={setMetaDesc}
                setMetaKw={setMetaKw}
                setOgtitle={setOgtitle}
                setOgDesc={setOgDesc}
                setOgSiteName={setOgSiteName}
                setTwitterCard={setTwitterCard}
                setTwitterTitle={setTwitterTitle}
                setTwitterSite={setTwitterSite}
                setTwitterDesc={setTwitterDesc}
                setMetaTitle={setMetaTitle}
              />
            }
          ></Route>
          <Route
            path="/business-mobile"
            element={
              <BussinessMobile
                setMetaDesc={setMetaDesc}
                setMetaKw={setMetaKw}
                setOgtitle={setOgtitle}
                setOgDesc={setOgDesc}
                setOgSiteName={setOgSiteName}
                setTwitterCard={setTwitterCard}
                setTwitterTitle={setTwitterTitle}
                setTwitterSite={setTwitterSite}
                setTwitterDesc={setTwitterDesc}
                setMetaTitle={setMetaTitle}
              />
            }
          ></Route>
          <Route
            path="/business-top-deals"
            element={
              <BussinessTopDeals
                setMetaDesc={setMetaDesc}
                setMetaKw={setMetaKw}
                setOgtitle={setOgtitle}
                setOgDesc={setOgDesc}
                setOgSiteName={setOgSiteName}
                setTwitterCard={setTwitterCard}
                setTwitterTitle={setTwitterTitle}
                setTwitterSite={setTwitterSite}
                setTwitterDesc={setTwitterDesc}
                setMetaTitle={setMetaTitle}
              />
            }
          ></Route>
          <Route
            path="/business-shop"
            element={
              <BussinessShop
                setMetaDesc={setMetaDesc}
                setMetaKw={setMetaKw}
                setOgtitle={setOgtitle}
                setOgDesc={setOgDesc}
                setOgSiteName={setOgSiteName}
                setTwitterCard={setTwitterCard}
                setTwitterTitle={setTwitterTitle}
                setTwitterSite={setTwitterSite}
                setTwitterDesc={setTwitterDesc}
                setMetaTitle={setMetaTitle}
              />
            }
          ></Route>
          <Route
            path="/cookie-policy"
            element={
              <CookiePolicy
                setMetaDesc={setMetaDesc}
                setMetaKw={setMetaKw}
                setOgtitle={setOgtitle}
                setOgDesc={setOgDesc}
                setOgSiteName={setOgSiteName}
                setTwitterCard={setTwitterCard}
                setTwitterTitle={setTwitterTitle}
                setTwitterSite={setTwitterSite}
                setTwitterDesc={setTwitterDesc}
                setMetaTitle={setMetaTitle}
              />
            }
          ></Route>
          <Route path="*" element={<Notfound />} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
