import React from "react";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import Body from "../Components/MakePayment/Body";
import Banner from "../Components/MakePayment/Banner";

function MakePayment(props) {
  const { business } = props;
  return (
    <>
      <div className={business ? "buisness-mobile" : ""}>
        <div className={business ? "buisness" : ""}>
          <Header bussinesspage={business ? true : false} />
          <div className="body-box-margin">
            <Banner />
            <Body />

            <Footer bussinesspage={business ? true : false} />
          </div>
        </div>
      </div>
    </>
  );
}

export default MakePayment;
