import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom'; // Assuming you're using React Router
import { useCookies } from 'react-cookie';

const CookieHandler = () => {
  const [cookies, setCookie, removeCookie] = useCookies(['dtt_reqid']);
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const dttReqId = queryParams.get('dtt_reqid');
    console.log(dttReqId);
    if (dttReqId) {
      setCookie('dtt_reqid', dttReqId, { path: '/' }); // Store the value of dtt_reqid in a cookie
    } else {
      removeCookie('dtt_reqid', { path: '/' }); // Remove the cookie if dtt_reqid is not present
    }
  }, [location.search, setCookie, removeCookie]);

  return null;
};

export default CookieHandler;
